import { useTranslation } from "react-i18next";
import { TestimonialsBlockDto } from "~/modules/pageBlocks/components/blocks/marketing/testimonials/TestimonialsBlockUtils";
import FancyTestimonialsSlider from "~/modules/recipe/components/FancyTestimonialSlider";
import TrustpilotBox from "./TrustpilotBox";

export default function TestimonialsVariantSimple({ item }: { item: TestimonialsBlockDto }) {
  const { t } = useTranslation();
  return (
    <section className="text-gray-600 dark:text-gray-400">
      <div className="container mx-auto space-y-12 px-5 py-24">
        {(item.headline || item.subheadline) && (
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <h2 className="text-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">{t(item.headline)}</h2>
            <p className="text-center text-xl text-gray-500">{t(item.subheadline)}</p>
            {item.reviews?.trustpilot && <TrustpilotBox trustpilot={item.reviews?.trustpilot} />}
          </div>
        )}
        <FancyTestimonialsSlider testimonials={item.items} />
      </div>
    </section>
  );
}
